import React, { useState, useCallback } from 'react';
import { Box, Paper } from '@mui/material';

// Components
import CustomButton from 'components/General/CustomButton';

// Custom Modals
import UploadImageModal from './UploadImageModal';

import { useSelector } from "react-redux";
import { useGetAnnotationsInformationMutation } from "state/api";
const selectToken = state => state.persistedReducer.token;

// Component to display a list of images
const ImageController = ({ url, setURL, imageName, setImageName, setAnnotations }) => {
    const [openModal, setOpenModal] = useState(false);
    const token = useSelector(selectToken);

    const handleUploadImage = () => {
        setOpenModal(true);
    }

    const handleAnalyzeImage = () => {
        // Call fetchInformation if imageName is not ''
        if (imageName !== '') fetchInformation();
    }

    const handleClose = () => {
        setOpenModal(false);
        console.log('image name:', imageName);

        setAnnotations([]);
    };

    const [getAnnotationsInformation] = useGetAnnotationsInformationMutation();

    // Function to fetch annotations information from the API
    const fetchInformation = useCallback(async () => {
        try {
            console.log(imageName)
            const response = await getAnnotationsInformation({ token, requestBody: { imageName: imageName } }).unwrap();
            if (response.success) {
                setAnnotations(response.annotations);
            } else {
                console.error('Failed to fetch annotations information:', response.message);
                setAnnotations([]);
            }
        } catch (error) {
            console.error('Failed to fetch annotations information:', error);
            setAnnotations([]);
        }
    }, [token, imageName]); // eslint-disable-line react-hooks/exhaustive-deps


    // Case 2. Show the list of images
    return (
        <Paper
            elevation={3}
            sx={{display: 'flex', flexDirection: 'column', justifyContent: 'center', alignItems: 'center', borderRadius: '10px', mb: '1rem'}}
        >

            {/* Modal */}
            <UploadImageModal open={openModal} handleClose={handleClose} setURL={setURL} setImageName={setImageName} />

            <Box sx={{width: '100%', height: '100%', overflowY: 'auto',
                    display: 'flex',
                    flexDirection: 'column',
                    justifyContent: 'center',
                    alignItems: 'center',
                    borderRadius: '10px',
                    padding: '1rem',
                    gap: "0.5rem",
                }}
            >
                <CustomButton text={"Upload File"} onClick={handleUploadImage} sx={{width: '100%'}}/>
                <CustomButton text={"Analyze Image"} onClick={handleAnalyzeImage} sx={{width: '100%'}}/>
            </Box>
        </Paper>
    );
};


export default ImageController;