import { CssBaseline, ThemeProvider } from "@mui/material";
import { createTheme } from "@mui/material/styles";
import { useMemo } from "react";
import { useSelector } from "react-redux";
import { BrowserRouter, Navigate, Routes, Route } from "react-router-dom";
import { themeSettings } from "theme";
import { DialogProvider } from './components/General/CustomDialog';

// Page Components
import LoginPage from "scenes/loginPage";
import MainPage from "scenes/mainPage";

// Protected Route Component
const ProtectedRoute = ({ children }) => {
    const isAuth = Boolean(useSelector((state) => state.persistedReducer.token));
    return isAuth ? children : <Navigate to="/" replace />;
};

// Main App Component
function App() {
    // Mode from Redux
    const theme = useMemo(() => createTheme(themeSettings()), []);

    return (
        <div className="app">
            <BrowserRouter>
                <DialogProvider>
                    <ThemeProvider theme={theme}>
                        <CssBaseline />
                        <Routes>
                            {/* Login Page */}
                            <Route path="/" element={<LoginPage />} />

                            {/* Main Layout for all the components (include NavBar and SideBar) */}
                            <Route path="/mainpage" element={<ProtectedRoute> <MainPage /> </ProtectedRoute>} />

                        </Routes>
                    </ThemeProvider>
                </DialogProvider>
            </BrowserRouter>
        </div>
    );
}

export default App;
