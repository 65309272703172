import { useState } from "react";
import { Box, Button, TextField, useMediaQuery, useTheme } from "@mui/material";
import { ThemeProvider } from "@mui/material/styles";
import { Formik } from "formik";
import { useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { setLogin } from "state";

// Modal
import WaitingModal from '../Modals/WaitingModal';

// API
import { useLoginUserMutation } from '../../../state/api';

// Custom theme
import formTheme from "./formTheme";


// Initial values for the registration and login forms
const initialValuesLogin = {userName: "", password: ""};

// Form component
const Form = ({ pageType, setPageType }) => {
    const theme = useTheme();
    const dispatch = useDispatch();
    const navigate = useNavigate();
    const isNonMobile = useMediaQuery("(min-width:600px)");
    const isLogin = pageType === "login";
    const isRegister = pageType === "register";

    // State for the waiting modal
    const [isWaiting, setIsWaiting] = useState(false);
    const [success, setSuccess] = useState(false);
    const [error, setError] = useState(false);

    // API mutations
    const [loginUser] = useLoginUserMutation();


    const handleLogin = async (values, onSubmitProps) => {
        try {
            // Step 1. Call the loginUser mutation
            const result = await loginUser(values).unwrap();

            // Step 2. Check if it succeeded and if a new password is required
            if(result.success) {
                // Step 2. Reset the form
                onSubmitProps.resetForm();

                // Step 3. Dispatch the setLogin action and navigate to the dashboard
                dispatch(setLogin({user: result.user, token: result.token}));
                navigate("/mainpage");
            }

        } catch (error) {
            // Handle error (e.g., show error message)
            console.error('Failed to login:', error);
            setError(true);
        }
    };

    const handleFormSubmit = async (values, onSubmitProps) => {
        if (isLogin) await handleLogin(values, onSubmitProps);
    };

    const handleClose = () => {
        // If it was a success, set the login view and reset the states\
        if (success){
            setPageType("login");
            setIsWaiting(false);
            setSuccess(false);
            setError(false);
        }

        // If it was an error, close the modal and reset the states
        if (error){
            setIsWaiting(false);
            setSuccess(false);
            setError(false);
        }
    }


    return (
        <ThemeProvider theme={formTheme}>

        <WaitingModal
            isOpen={isWaiting || success || error}
            message={success ? "We received your request! Check your email to confirm your account." :
                    (error ? "Error" : "Creating user...")}
            success={success}
            error={error}
            onClose={handleClose}
        />


        <Formik onSubmit={handleFormSubmit} initialValues={initialValuesLogin}>
            {({ values, errors, touched, handleBlur, handleChange, handleSubmit }) => (
                <form onSubmit={handleSubmit}>
                    <Box
                        display="grid"
                        gap="1rem"
                        gridTemplateColumns="repeat(4, minmax(0, 1fr))"
                        margin= "2rem 0"
                        sx={{
                        "& > div": { gridColumn: isNonMobile ? undefined : "span 4" },
                        }}
                    >

                        {/* USERNAME AND PASSWORD */}
                        {isRegister === false && (
                            <>
                                <TextField
                                    label="Username"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.userName}
                                    name="userName"
                                    error={Boolean(touched.userName) && Boolean(errors.userName)}
                                    helperText={touched.userName && errors.userName}
                                    sx={{ gridColumn: "span 4" }}
                                />

                                <TextField
                                    label="Password"
                                    type="password"
                                    onBlur={handleBlur}
                                    onChange={handleChange}
                                    value={values.password}
                                    name="password"
                                    error={Boolean(touched.password) && Boolean(errors.password)}
                                    helperText={touched.password && errors.password}
                                    sx={{ gridColumn: "span 4" }}
                                />
                            </>
                        )}

                    </Box>

                    {/* BUTTONS */}
                    <Box>
                        {/* LOGIN BUTTON */}
                        <Button
                            fullWidth
                            type="submit"
                            sx={{
                                backgroundColor: theme.palette.primary[600], // Set background color to primary color
                                textDecoration: 'none',
                                color: 'white',
                                margin: "1rem 0",
                                padding: "1rem",
                                fontSize: '1rem', // Adjust font size as needed
                                textTransform: 'none', // Prevent uppercase transformation
                                '&:hover': {
                                    textDecoration: 'none',
                                    backgroundColor: theme.palette.primary[800], // Darken button on hover
                                },
                            }}
                        >
                            LOGIN
                        </Button>

                    </Box>
                </form>
            )}
        </Formik>
        </ThemeProvider>
    );
};

export default Form;