// React and MUI imports
import React, { useRef, useState, useEffect } from 'react';
import { Backdrop, CircularProgress, Box, Paper, Typography, useTheme, } from '@mui/material';

import * as tf from '@tensorflow/tfjs';

// Konva
import Konva from 'konva';
import { Stage, Layer, Image, Line } from 'react-konva';
import useImage from 'use-image';
import simplify from 'simplify-js';
import contour2D from 'contour-2d';
import pack from 'ndarray-pack';
import { Image as ImageJS } from 'image-js';

// API
import { useSelector } from "react-redux";

// Import toolbar
import ImageOptionBar from './ImageOptionBar';

// Image Layer with Memo to avoid re-rendering
const ImageLayer = React.memo(({ image, imageRef, brightness, contrast }) => {
    return (
        <Layer>
            <Image
                ref={imageRef}
                image={image}
                filters={[Konva.Filters.Brighten, Konva.Filters.Contrast]}
                brightness={brightness}
                contrast={contrast}
            />
        </Layer>
    );
});

// Random ID generator (based on the current time)
const generateId = () => {
    return Date.now().toString();
};

// Function to convert hex to rgba
function hexToRGBA(hex, alpha = 0.5) {
    // Remove the hash at the start if it's there
    hex = hex.replace(/^#/, '');

    // Parse the r, g, b values
    let r, g, b;
    if (hex.length === 3) {
        // If it's a shorthand hex color
        r = parseInt(hex[0] + hex[0], 16);
        g = parseInt(hex[1] + hex[1], 16);
        b = parseInt(hex[2] + hex[2], 16);
    } else if (hex.length === 6) {
        // If it's a full hex color
        r = parseInt(hex.substring(0, 2), 16);
        g = parseInt(hex.substring(2, 4), 16);
        b = parseInt(hex.substring(4, 6), 16);
    } else {
        throw new Error("Invalid hex color: " + hex);
    }

    // Return the rgba() formatted color
    return `rgba(${r}, ${g}, ${b}, ${alpha})`;
}

// Image Editor component
const ImageEditor = ( { accessToken, annotations, setAnnotations, inputModel, annotationSelected } ) => {
    const theme = useTheme();
    const [image] = useImage(accessToken, 'Anonymous'); // Anonymous to use CORS with konva
    const user = useSelector((state) => state.persistedReducer.user);
    const [model, setModel] = useState(inputModel);

    // Use Effect to load the model every time the input model changes
    useEffect(() => {
        setModel(inputModel);
    }, [inputModel]);

    // Refs for the stage, paper, and image
    const stageRef = useRef(null);
    const paperRef = useRef(null);
    const imageRef = useRef(null);

    // Sate size and original dimensions
    const [stageSize, setStageSize] = useState({ width: 500, height: 100 });

    // Editor initial state
    const initialState = {
        scale: 1,
        position: { x: 0, y: 0 },
        drawing: false,
        lines: [],
        shapes: [],
        currentMode: 'PANNING',
        brightness: 0,
        contrast: 0,
        strokeSize: 5,
        selectedLabel: { 'text': '', 'color': '#000000' },
        tooltipOpen: false,
        tooltipContent : '',
        tooltipPosition: { x: 0, y: 0 },
        highlightedShapeId: null,
        toolbarDisabled: true,
    };
    const [state, setState] = useState(initialState);

    // Function to update the state
    const updateState = (newValues) => {
        setState((prevState) => ({
            ...prevState,
            ...newValues,
        }));
    };

    // Place the image in the center of the canvas at the start
    useEffect(() => {
        if (image && stageRef.current) {
            // Determine scale to fit the image in the canvas, maintaining aspect ratio
            const scaleX = stageSize.width / image.width;
            const scaleY = stageSize.height / image.height;
            const scaleToFit = Math.min(scaleX, scaleY);

            // Calculate centered position based on scaled image size
            const centeredX = (stageSize.width - (image.width * scaleToFit)) / 2;
            const centeredY = (stageSize.height - (image.height * scaleToFit)) / 2;

            updateState({
                scale: scaleToFit,
                position: { x: centeredX, y: centeredY },
                originalDimensions: { width: image.width, height: image.height },
            });

            // Apply transformations to stage for immediate visual update
            stageRef.current.scale({ x: scaleToFit, y: scaleToFit });
            stageRef.current.position({ x: centeredX, y: centeredY });
            stageRef.current.batchDraw();
        }
    }, [image, stageRef]); // eslint-disable-line react-hooks/exhaustive-deps

    // Cache the image when it's loaded (for brightness and contrast filters)
    useEffect(() => {
        if (image) {
            imageRef.current.cache();
            imageRef.current.getLayer().batchDraw();

            // Enable the toolbar
            updateState({ toolbarDisabled: false });
        }
    }, [image]);

    // Update the stage size when the paper ref changes
    useEffect(() => {
        const updateStageSize = () => {
            if (paperRef.current) {
                setStageSize({
                    width: paperRef.current.offsetWidth,
                    height: paperRef.current.offsetHeight,
                });
            }
        };

        // Update stage size initially
        updateStageSize();
        window.addEventListener('resize', updateStageSize);

        // Cleanup the event listener on component unmount
        return () => window.removeEventListener('resize', updateStageSize);
    }, []);

    // Initialize the shapes based on the current image
    useEffect(() => {
        if (annotations.length > 0) {
            updateState({ shapes: annotations });
        }
        else{
            updateState({ shapes: [] });
        }

    }, [annotations]);

    // Zoom in and out with the mouse wheel
    const handleWheel = (e) => {
        if (state.currentMode !== 'PANNING') return; // Only zoom if panning is enabled

        e.evt.preventDefault();
        const scaleBy = e.evt.deltaY > 0 ? 0.9 : 1.1; // Faster zoom on wheel scroll

        const stage = e.target.getStage();
        const oldScale = stage.scaleX();
        const pointer = stage.getPointerPosition();

        const mousePointTo = {
            x: (pointer.x - stage.x()) / oldScale,
            y: (pointer.y - stage.y()) / oldScale,
        };

        const newScale = oldScale * scaleBy;

        const newPos = {
            x: pointer.x - mousePointTo.x * newScale,
            y: pointer.y - mousePointTo.y * newScale,
        };

        // Set the new scale and position
        updateState({ scale: newScale });
        updateState({ position: newPos });
    };

    // Mouse down handlers
    const handleMouseDown = (e) => {
        if (state.currentMode === 'DRAWING') {
            updateState({ drawing: true });
            // Get the current stage
            const stage = stageRef.current.getStage();
            // Get the mouse position relative to the stage
            const pos = stage.getPointerPosition();
            // Adjust the position based on the stage's scale and position
            const adjustedPos = {
                x: (pos.x - stage.x()) / stage.scaleX(),
                y: (pos.y - stage.y()) / stage.scaleX(), // Assuming uniform scaling for simplicity
            };
            updateState({ lines: [...state.lines, { points: [adjustedPos.x, adjustedPos.y] }] });
        }
    };

    // Mouse move handlers
    const handleMouseMove = (e) => {
        if (!state.drawing || state.currentMode !== 'DRAWING') return;

        requestAnimationFrame(() => {
            const stage = stageRef.current.getStage();
            const point = stage.getPointerPosition();
            // Adjust the point based on the stage's transformation
            const adjustedPoint = {
                x: (point.x - stage.x()) / stage.scaleX(),
                y: (point.y - stage.y()) / stage.scaleX(),
            };

            let lastLine = state.lines[state.lines.length - 1];
            // Update the last line with the new, adjusted point
            lastLine.points = lastLine.points.concat([adjustedPoint.x, adjustedPoint.y]);
            state.lines.splice(state.lines.length - 1, 1, lastLine);
            updateState({ lines: state.lines.concat() });
        });
    };

    // Mouse up handler
    const handleMouseUp = () => {
        updateState({ drawing: false });
    };

    // Shape click handler
    const handleShapeClick = (shapeId) => {
        // If in inspection mode, highlight the clicked shape
        if (state.currentMode === 'INSPECTION') {
            // Toggle the highlight off if the same shape is clicked again
            if (shapeId === state.highlightedShapeId) {
                updateState({ highlightedShapeId: null });
                annotationSelected('');
            } else {
                updateState({ highlightedShapeId: shapeId });
                annotationSelected(shapeId);
            }
        }

        // If in delete mode, delete the clicked shape
        if (state.currentMode === 'DELETE') {
            // Remove the shape from the shapes array and update the state
            updateState({
                shapes: state.shapes.filter(shape => shape.id !== shapeId),
                highlightedShapeId: null
            });

            // Remove the shape from the annotations array on currentImage
            setAnnotations(annotations.filter(annotation => annotation.id !== shapeId));
        }
    };

    // Return the component
    return (
        <Paper
            elevation={3}
            sx={{
                display: 'flex',
                flexDirection:'column',
                flexGrow:1,
                overflow: 'hidden',
                backgroundColor:theme.palette.secondary[100],
                border: "1px solid #ccc",
                borderRadius: '10px'
            }}
        >

            <Box ref={paperRef} sx={{flexGrow:1}}>

                <Stage
                    ref={stageRef}
                    width={stageSize.width}
                    height={stageSize.height}
                    scaleX={state.scale}
                    scaleY={state.scale}
                    x={state.position.x}
                    y={state.position.y}
                    draggable={state.currentMode === 'PANNING'}
                    onWheel={handleWheel}
                    onMouseDown={handleMouseDown}
                    onMousemove={handleMouseMove}
                    onMouseup={handleMouseUp}
                    onDragEnd={() => {updateState({ position: { x: stageRef.current.x(), y: stageRef.current.y() } });}}
                >
                    <ImageLayer image={image} imageRef={imageRef} stageSize={stageSize} brightness={state.brightness} contrast={state.contrast} />

                    <Layer>
                        {state.lines.map((line, i) => (
                            <Line
                                key={i}
                                points={line.points}
                                stroke={state.selectedLabel.color}
                                strokeWidth={state.strokeSize}
                                tension={0.0}
                                lineCap="round"
                                globalCompositeOperation="source-over"
                            />
                        ))}
                    </Layer>

                    <Layer>
                        {state.shapes.map((shape) => (
                            <Line
                                key={shape.id}
                                points={shape.points}
                                stroke={state.currentMode === 'INSPECTION' ? (state.highlightedShapeId === shape.id ? shape.stroke : theme.palette.primary[200]) : shape.stroke}
                                strokeWidth={shape.strokeWidth}
                                closed={true}
                                onClick={() => handleShapeClick(shape.id)}
                                onMouseEnter={(e) => {
                                    if (state.currentMode === 'INSPECTION') {
                                        updateState({ tooltipOpen: true })
                                        updateState({ tooltipContent:
                                            <Typography variant="h1" style={{ fontSize: '1rem' }}>
                                                {shape.text || 'Unknown Pathology'}
                                                {shape.confidence ? `Confidence: ${shape.confidence}%` : ''}
                                            </Typography>
                                        });
                                        updateState({ tooltipPosition: { x: e.evt.clientX, y: e.evt.clientY } })
                                    }
                                }}
                                onMouseLeave={() => {
                                    if (state.currentMode === 'INSPECTION') {
                                        updateState({ tooltipOpen: false });
                                    }
                                }}
                                fill={state.currentMode === 'INSPECTION' ? (state.highlightedShapeId === shape.id ? shape.fill : hexToRGBA(theme.palette.primary[200])) : shape.fill}
                            />
                        ))}
                    </Layer>

                </Stage>

            </Box>

        </Paper>
    );
}

export default ImageEditor;