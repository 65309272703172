import { Box, Typography, useTheme, useMediaQuery, Button } from "@mui/material";
import { useState } from "react";
import LoginForm from "../../components/Forms/LoginForm/LoginForm";

// Images
const logoWhite = `/assets/images/logo/logoHS.svg`;

// LoginPage component
const LoginPage = () => {
    const theme = useTheme();
    const [pageType, setPageType] = useState("login");
    const isNonMobileScreens = useMediaQuery(theme.breakpoints.up('lg'));

    // Return the JSX for the LoginPage component
    return (
        // Main box to hold the entire page
        <Box minHeight="100vh" bgcolor={"#f5f5f5"}>

            {/* TopBar with the logo */}
            <Box width="100%" height="70px" bgcolor={'#003082'} p={theme.spacing(3)} display="flex" justifyContent="space-between" alignItems="center">

                {/* Logo */}
                <Box display="flex" alignItems="center" height="70px" maxWidth="100%" pl='2rem' pr='2rem' gap='1rem'>
                    <img src={logoWhite} alt="Logo" style={{ width: '50px', height: '50px'}} />
                    <Typography variant="h4" component="h4" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.5rem' }}>HS One Advanced AI Research Lab</Typography>
                </Box>

                <Typography variant="h5" component="h5" sx={{ color: 'white', fontSize: '1.25rem' }}>Clínica byTeeth</Typography>
            </Box>

            {/* Main Content */}
            <Box
                display="grid"
                gridTemplateColumns={isNonMobileScreens ? '1fr' : '1fr'}
                gap={theme.spacing(4)}
                justifyContent="center"
                alignItems="center"
                pl = {theme.spacing(16)}
                pr = {theme.spacing(16)}
                pt = {theme.spacing(6)}
                pb = {theme.spacing(6)}
                width="100%"
                height="85vh" // Adjust the height as necessary

            >

                 {/* Form Section */}
                 <Box display="flex" justifyContent="center" alignItems="center" height="100%">
                    <Box
                        bgcolor="white"
                        p={theme.spacing(8)}
                        borderRadius={theme.shape.borderRadius}
                        boxShadow={theme.shadows[5]}
                        m={isNonMobileScreens ? theme.spacing(2) : 'auto'}
                        textAlign="center"
                        justifyContent="center"
                        maxWidth={{ xs: '100%', md: '600px' }}
                        width="100%"
                        gap={theme.spacing(4)}
                    >
                        <Typography fontWeight="bold" variant="h1" mb={1} color={theme.palette.primary[600]}>
                            Periapical Diagnosis
                        </Typography>

                        <Typography variant="h5" mb={8} color={theme.palette.grey[600]}>
                            Empower your diagnostic abilities
                        </Typography>

                        <LoginForm pageType={pageType} setPageType={setPageType}/>
                    </Box>
                </Box>


            </Box>

        </Box>
    );
};

export default LoginPage;
