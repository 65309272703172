import React, {useState, useEffect} from "react";
import FlexBetween from "components/General/FlexBetween";
import Header from "components/General/Header";
import { DownloadOutlined } from "@mui/icons-material";
import { Box, Typography, Divider, Checkbox, Button, useTheme } from "@mui/material";


// Components
import ImageController from "components/Periapicales/ImageController";
import ImageEditor from 'components/ImageEditor/ImageEditor';
import CustomButton from 'components/General/CustomButton';

const CATEGORY_COLORS = {
    'Caries': { stroke: '#FF0000', fill: 'rgba(255, 0, 0, 0.5)' },
    'LesionPeriapical': { stroke: '#00FF00', fill: 'rgba(0, 255, 0, 0.5)' },
    'PerdidaHueso': { stroke: '#0000FF', fill: 'rgba(0, 0, 255, 0.5)' },
    'Endodoncia': { stroke: '#FFFF00', fill: 'rgba(255, 255, 0, 0.5)' },
    'Obturacion': { stroke: '#FF00FF', fill: 'rgba(255, 0, 255, 0.5)' },
    'Corona': { stroke: '#00FFFF', fill: 'rgba(0, 255, 255, 0.5)' },
    'Poste': { stroke: '#800080', fill: 'rgba(128, 0, 128, 0.5)' },
    'Implante': { stroke: '#808080', fill: 'rgba(128, 128, 128, 0.5)' },
    'Atricion': { stroke: '#FFA500', fill: 'rgba(255, 165, 0, 0.5)' },
    'Sarro': { stroke: '#A52A2A', fill: 'rgba(165, 42, 42, 0.5)' }
};

const Legend = ({ setAnnotationsSelected }) => {
    // Initialize checkedCategories with all keys from CATEGORY_COLORS
    const [checkedCategories, setCheckedCategories] = useState(Object.keys(CATEGORY_COLORS));

    useEffect(() => {
        // Update annotationsSelected in MainPage with all categories initially
        setAnnotationsSelected(checkedCategories);
    }, [checkedCategories, setAnnotationsSelected]);

    const handleSelectAll = () => {
        setCheckedCategories(Object.keys(CATEGORY_COLORS));
    };

    const handleUnselectAll = () => {
        setCheckedCategories([]);
    };

    const handleToggle = (value) => {
        const currentIndex = checkedCategories.indexOf(value);
        const newChecked = [...checkedCategories];

        if (currentIndex === -1) {
            newChecked.push(value);
        } else {
            newChecked.splice(currentIndex, 1);
        }

        setCheckedCategories(newChecked);
    };

    return (
        <Box
            flexGrow={1}
            sx={{
                display: 'flex',
                flexDirection: 'column',
                justifyContent: 'center',
                alignItems: 'left',
                padding: '1rem',
                gap: '1.0rem',
                '& .colorCircle': {
                    width: 40,
                    height: 40,
                    borderRadius: '50%',
                    border: '1px solid #000',
                    marginRight: 2, // theme.spacing(1)
                    display: 'inline-block',
                },
                '& .colorLabel': {
                    fontWeight: 'bold',
                    fontSize: '1.2rem',
                    marginRight: 2, // theme.spacing(2)
                    display: 'flex',
                    alignItems: 'center',
                },
            }}
        >
            <Box display={'flex'} flexDirection={'row'} gap={'1rem'} mt='0rem' mb="1rem">
                <CustomButton text={"Select All"} onClick={handleSelectAll} sx={{width: '100%'}}/>
                <CustomButton text={"Deselect All"} onClick={handleUnselectAll} sx={{width: '100%'}}/>
            </Box>

            {Object.entries(CATEGORY_COLORS).map(([label, colors]) => (
                <Box key={label} sx={{ display: 'flex', alignItems: 'center' }}>
                    <Checkbox
                        checked={checkedCategories.indexOf(label) !== -1}
                        onChange={() => handleToggle(label)}
                        inputProps={{ 'aria-label': 'controlled' }}
                    />
                    <span className="colorCircle" style={{ backgroundColor: colors.fill }}></span>
                    <Typography variant="h6" component="h6" color="textSecondary" sx={{ fontWeight: 'bold', fontSize: '1rem' }}>{label}</Typography>
                </Box>
            ))}
        </Box>
    );
};

// Images
const logoWhite = `/assets/images/logo/logoHS.svg`;

const MainPage = () => {
    const theme = useTheme();
    const [imageName, setImageName] = useState('');
    const [annotations, setAnnotations] = useState([]);
    const [annotationsSelected, setAnnotationsSelected] = useState(Object.keys(CATEGORY_COLORS));
    const [filteredAnnotations, setFilteredAnnotations] = useState([]);

    const [url, setURL] = useState('');

    // UseEffect to clear the annotations when the url changes
    useEffect(() => {
        setAnnotations([]);
    }, [url]);

    useEffect(() => {
        const newFilteredAnnotations = annotations.filter(annotation => annotationsSelected.includes(annotation.text));
        setFilteredAnnotations(newFilteredAnnotations);
    }, [annotations, annotationsSelected]);

    return (
        <Box minHeight="95vh" bgcolor={"#f5f5f5"} display='flex' flexDirection='column'>

            {/* TopBar with the logo */}
            <Box width="100%" height="70px" bgcolor={'#003082'} p={theme.spacing(3)} display="flex" justifyContent="space-between" alignItems="center">

                {/* Logo */}
                <Box display="flex" alignItems="center" height="70px" maxWidth="100%" pl='2rem' pr='2rem' gap='1rem'>
                    <img src={logoWhite} alt="Logo" style={{ width: '50px', height: '50px'}} />
                    <Typography variant="h4" component="h4" sx={{ color: 'white', fontWeight: 'bold', fontSize: '1.5rem' }}>HS One Advanced AI Research Lab</Typography>
                </Box>

                <Typography variant="h5" component="h5" sx={{ color: 'white', fontSize: '1.25rem' }}>Clínica byTeeth</Typography>
            </Box>

            {/* Patient Information */}
            <Box width="100%" height="100px" bgcolor={'#E6ECF7'} p={theme.spacing(3)} display="flex" justifyContent="space-between" alignItems="center">

                {/* Logo */}
                <Box display="flex" alignItems="center" height="70px" maxWidth="100%" pl='2rem' gap='1rem'>
                    <Typography variant="h5" component="h5" sx={{ color: '#003082', fontSize: '1.5rem' }}>Abád Sanchez, <strong>Javier</strong></Typography>
                    <Divider orientation="vertical" variant="middle" flexItem />

                    <Box display="flex" alignItems="left" gap='0.05rem' justifyContent='center' flexDirection='column'>
                        <Typography variant="h6" component="h6" sx={{ color: '#003082', fontSize: '1.0rem' }}>43 Años · Historial: 1014</Typography>
                        <Typography variant="h6" component="h6" sx={{ color: '#003082', fontSize: '1.0rem' }}>Tarifa:</Typography>
                    </Box>

                </Box>

            </Box>


            <Box flexGrow={1} width='100%' display="grid" gridTemplateColumns="repeat(12, 1fr)" gridTemplateRows="repeat(8, 1fr)" gap="20px" p={theme.spacing(3)}>

                <Box display="flex" gridColumn="span 9" gridRow="span 8" color={theme.palette.secondary[1000]}>
                    <ImageEditor key={url} accessToken={url} annotations={filteredAnnotations}/>
                </Box>

                <Box display="flex" flexDirection="column" gridColumn="span 3" gridRow="span 8" color={theme.palette.secondary[1000]}>
                    <ImageController setImageName={setImageName} setURL={setURL} url={url} imageName={imageName} setAnnotations={setAnnotations} />
                    <Legend setAnnotationsSelected={setAnnotationsSelected} />
                </Box>

            </Box>

        </Box>
    );
};

export default MainPage;